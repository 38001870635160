export default {
    methods:{
        handleRegister(obj){
            return new Promise((resolve, reject) => {
                // const bodyFormData = new FormData();
                // bodyFormData.append("company",obj.company);
                // bodyFormData.append("firstname", obj.firstname);
                // bodyFormData.append("lastname", obj.lastname);
                // bodyFormData.append("email", obj.email);
                // bodyFormData.append("country", obj.country);
                // bodyFormData.append("industry", obj.industry);
                // bodyFormData.append("should_invite",obj.should_invite);
                const data = {
                    company:obj.company,
                    firstname:obj.firstname,
                    lastname:obj.lastname,
                    email:obj.email,
                    country:obj.country,
                    industry:obj.industry,
                    should_invite:obj.should_invite
                }
                const options = {
                    method: "POST",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/customer/sign-up`,
                    data: data,
                };
                this.$http(options)
                    .then(res => { resolve(res) })
                    .catch(err => { reject(err) })
            })
        }
    }
}