<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img
          style="max-width: 140px"
          src="@/assets/images/logo/Secucy-logo.png"
          alt="logo"
        />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Welcome to Secusy.ai! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-up and start the adventure
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- Company name -->
              <b-form-group label="Company Name" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="Company Name"
                  rules="required"
                >
                  <b-form-input
                    id="login-email"
                    v-model="company"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="Company Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- First name -->
              <b-form-group label="First Name" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="First Name"
                  rules="required"
                >
                  <b-form-input
                    id="login-email"
                    v-model="fname"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="First Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Last name -->
              <b-form-group label="Last Name" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="Last Name"
                  rules="required"
                >
                  <b-form-input
                    id="login-email"
                    v-model="lname"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="Last Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!--Email-->
              <b-form-group label="Email" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="emailid"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="Email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!--Country-->
              <b-form-group label="Country" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="Country"
                  rules="required"
                >
                  <select name="" id="" class="form-control" v-model="country">
                    <option
                      :value="country.name"
                      v-for="country in countrylist"
                      :key="country._id"
                    >
                      {{ country.name }}
                    </option>
                  </select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!--Industry-->
              <b-form-group label="Industry" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="Industry"
                  rules="required"
                >
                  <select name="" id="" class="form-control" v-model="industry">
                    <option
                      :value="industry.name"
                      v-for="industry in industrylist"
                      :key="industry._id"
                    >
                      {{ industry.name }}
                    </option>
                  </select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Captcha"
                  rules="required"
                >
                  <vue-recaptcha
                    ref="recaptcha"
                    :sitekey="GOOGLE_CAPTCHA_SITE_KEY"
                    style="display: flex; justify-content: center; height: 78px"
                    @verify="verifyCaptcha"
                    @expired="captchaExpired"
                  ></vue-recaptcha>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group> -->

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                <b-spinner v-if="waiting" class="mr-50" variant="light" small />
                <span class="align-middle">{{ this.waiting ? "Please Wait.." : "Sign Up" }}</span>
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>Already have an account? </span>
            <b-link :to="{ name: 'login' }">
              <span>&nbsp;Click to Login</span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import RegisterMixin from "../../mixins/RegisterMixins";
import ResponseMixins from "../../mixins/ResponseMixins";
import { VueReCaptcha  } from "vue-recaptcha-v3";
import Vue from "vue";

Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_GOOGLE_CAPTCHA_SITE_KEY })


export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    BSpinner
  },
  mixins: [togglePasswordVisibility, RegisterMixin, ResponseMixins],
  data() {
    return {
      status: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      company: "",
      fname: "",
      lname: "",
      emailid: "",
      country: "",
      industry: "",
      waiting: false,
      countrylist: [],
      industrylist: [],
      showSpinner: false,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  mounted() {
    this.getCountry();
    this.getIndustry();
  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.waiting = true;
          this.Signup();
        }
      });
      // this.AddUser();
    },


    getCountry() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + `/countries`,
      };
      this.$http(options)
        .then((res) => {
          this.countrylist = res.data.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getIndustry() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + `/industries`,
      };
      this.$http(options)
        .then((res) => {
          this.industrylist = res.data.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    Signup() {
      this.$recaptcha("login")
        .then((token) => {
          let data = {
            company: this.company,
            firstname: this.fname,
            lastname: this.lname,
            email: this.emailid,
            mobile: this.phonenumber,
            should_invite: true,
            country: this.country,
            industry: this.industry,
            is_public_signup: 1,
          };
          data["g-recaptcha-response"] = token;
          const options = {
            method: "POST",
            data: data,
            headers: { "content-type": "application/json" },
            url: process.env.VUE_APP_BASEURL + `/customer/sign-up`,
          };
          this.$http(options)
            .then((res) => {
              if (res.data.status == "error") {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: res.data.message,
                    icon: "EditIcon",
                    variant: "danger",
                  },
                });
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: res.data.message,
                    icon: "EditIcon",
                    variant: "success",
                  },
                });
              }
              this.$refs.loginValidation.reset();
              this.company = "";
              this.fname = "";
              this.lname = "";
              this.emailid = "";
              this.phonenumber = "";
              this.country = "";
              this.industry = "";

              this.waiting = false;
            })
            .catch((err) => {
              console.log(err);
              if (err.response.data.errors.email) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: err.response.data.errors.email[0],
                    icon: "UserCheckIcon",
                    variant: "danger",
                  },
                });
              } else {
                this.handleError(err);
              }
            });
        })
        .catch((err) => {
          console.log(err);
          this.showToast("CAPTCHA Verification Failed", "danger");
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
